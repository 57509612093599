import {
  bodyFont,
  Button,
  Collapsible,
  colors,
  Column,
  Container,
  respondTo,
  Row,
  titleFont,
} from '@123-front/ui-kit';
import '@brainhubeu/react-carousel/lib/style.css';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Banner from '../../../components/banner';
import Card from '../../../components/card';
import FlexCenter from '../../../components/flex-center';
import FooterLanding from '../../../components/footer-landing';
import GroupingLine from '../../../components/GroupingLine';
import Hero from '../../../components/hero';
import Layout from '../../../components/layout';
import OpinionsCarousel from '../../../components/opinions-carousel';
import SEO from '../../../components/seo';

const ButtonCotizar = styled.div({
  [respondTo('xs', 'md')]: {
    marginTop: -107,
  },
});

const SectionTitle = styled.h2({
  ...titleFont('md', 'serif'),
  color: colors.gray.darkest,
  textAlign: 'center',
});

const HeroSection = styled.section({
  [respondTo('xs', 'md')]: { height: 280 },
  [respondTo('md')]: { height: 400 },
});

const CardSection = styled.section({
  [respondTo('xs', 'md')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 72,
    paddingBottom: 100,
  },
  [respondTo('md')]: { paddingTop: 140, paddingBottom: 140 },
});

const OpinionSection = styled.section({
  background: colors.gray.ultralight,
  [respondTo('xs', 'md')]: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  [respondTo('md')]: {
    paddingTop: 60,
    paddingBottom: 70,
  },
});

const ExpertSection = styled.section({
  [respondTo('xs', 'md')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 100,
    paddingBottom: 120,
  },
  [respondTo('md')]: { paddingTop: 140, paddingBottom: 160 },
});

const AdvisorImg = styled.img({
  width: 270,
  height: 217,
  [respondTo('xs', 'md')]: {
    marginBottom: 16,
  },
  [respondTo('md')]: {
    marginTop: -76,
  },
});

const OrganicLandingMx: React.FC = () => {
  const backgrounds = useStaticQuery(graphql`
    query {
      mobile: s3Object(localFile: { name: { eq: "photo_landing_mex_hero_mobile" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      desktop: s3Object(localFile: { name: { eq: "photo_landing_mex_hero_desktop" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `);

  return (
    <Layout gtmId="GTM-K39GH72">
      <SEO title="Seguros de Autos. Cotizaciones de Seguro de Auto" />
      <HeroSection id="hero">
        <Hero
          desktopBackground={backgrounds.desktop.localFile.childImageSharp.fluid}
          mobileBackground={backgrounds.mobile.localFile.childImageSharp.fluid}
          title="Cotizar Seguro de Auto"
          subtitle="Encuentra el mejor\nseguro de auto"
        />
        <FlexCenter>
          <ButtonCotizar>
            <Button size="lg">Cotizar seguro</Button>
          </ButtonCotizar>
        </FlexCenter>
      </HeroSection>
      <CardSection id="cards">
        <FlexCenter>
          <SectionTitle>Un seguro de auto que se adapta a ti</SectionTitle>
        </FlexCenter>
        <Container desktop="12" mobile="4">
          <Row>
            <Card
              image="https://branding.s3.us-west-2.amazonaws.com/images/img_tech.svg"
              title="Todo a un click de distancia"
              body="Puedes cotizar tu seguro de auto online y encontrar la cobertura a tu medida desde donde te encuentres."
            ></Card>
            <Card
              image="https://branding.s3.us-west-2.amazonaws.com/images/img_callcenter.svg"
              title="Estamos contigo"
              body="Te asesoramos en el proceso de contratación y acompañamos durante toda la vida de tu póliza."
            ></Card>
            <Card
              image="https://branding.s3.us-west-2.amazonaws.com/images/img_experience.svg"
              title="Planes imperdibles"
              body="La cobertura que necesitas al precio más conveniente. Elige el seguro para tu auto, nosotros lo gestionamos."
            ></Card>
          </Row>
        </Container>
      </CardSection>
      <GroupingLine />
      <OpinionSection id="opinions">
        <FlexCenter>
          <SectionTitle>Opiniones reales, de clientes reales</SectionTitle>
        </FlexCenter>
        <Container>
          <OpinionsCarousel />
        </Container>
      </OpinionSection>
      <GroupingLine />
      <ExpertSection id="experts">
        <FlexCenter>
          <SectionTitle>Somos expertos en Seguros</SectionTitle>
        </FlexCenter>
        <FlexCenter>
          <Container>
            <Row>
              <Column desktop="3">
                <FlexCenter>
                  <AdvisorImg src="https://branding.s3.us-west-2.amazonaws.com/photos/photo_landing_advisors.png"></AdvisorImg>
                </FlexCenter>
              </Column>
              <Column desktop="6">
                <p style={{ textAlign: 'center' }}>
                  Somos un broker digital de seguros pioneros en Latinoamérica. Nacimos en 2010 con
                  el objetivo de hacer transparente la contratación de seguros. Asesoramos a más de
                  un millón de personas en distintas partes de LaTam.
                </p>
                <Container>
                  <Row>
                    <Column desktop="4" mobile="4">
                      <div
                        style={{
                          borderLeft: `4px solid ${colors.gray.lighten}`,
                          paddingLeft: 12,
                          paddingTop: 5,
                          paddingBottom: 5,
                          height: '100%',
                        }}
                      >
                        <div style={titleFont('sm')}>+ 35.000</div>
                        <div style={bodyFont('md')}>Consultas resueltas por año</div>
                      </div>
                    </Column>
                    <Column desktop="4" mobile="4">
                      <div
                        style={{
                          borderLeft: `4px solid ${colors.gray.lighten}`,
                          paddingLeft: 12,
                          paddingTop: 5,
                          paddingBottom: 5,
                          height: '100%',
                        }}
                      >
                        <div style={titleFont('sm')}>+ 5.500</div>
                        <div style={bodyFont('md')}>Siniestros resueltos por año</div>
                      </div>
                    </Column>
                    <Column desktop="4" mobile="4">
                      <div
                        style={{
                          borderLeft: `4px solid ${colors.gray.lighten}`,
                          paddingLeft: 12,
                          paddingTop: 5,
                          paddingBottom: 5,
                          height: '100%',
                        }}
                      >
                        <div style={titleFont('sm')}>95 %</div>
                        <div style={bodyFont('md')}>Clientes satisfechos</div>
                      </div>
                    </Column>
                  </Row>
                </Container>
              </Column>
            </Row>
            <Row>
              <FlexCenter style={{ paddingTop: 72 }}>
                <Column desktop="6">
                  <Collapsible title="¿Qué es 123Seguro?" type="list">
                    <p>
                      Somos un <strong>broker de seguros </strong> online líder. En 123Seguro
                      brindamos una solución integral, te ofrecemos una multi-cotización, además de
                      asesoramiento en la contratación, siniestros y de cualquier trámite
                      relacionado con tu póliza de seguro.
                    </p>
                  </Collapsible>
                  <Collapsible
                    title="¿Cuál es la importancia de contar con Seguros de Autos en México?"
                    type="list"
                  >
                    <p>
                      En primer lugar para circular debes contar con el{' '}
                      <strong>Seguro de Auto </strong> de Responsabilidad Civil o mejor conocido
                      como seguro de auto de Daños a Terceros, es obligatorio desde el 2019 en
                      México. Pero lo más importante es contar con un Seguro de Auto para proteger
                      tu integridad, la de tus acompañantes y la de terceros. Además por supuesto de
                      prever gastos en un posible siniestro que lamentablemente a todos nos puede
                      suceder.
                    </p>
                  </Collapsible>
                  <Collapsible title="¿Cómo cotizar mi Seguro de Auto?" type="list">
                    <p>
                      Para poder cotizar tu seguro debes hacer click en “Cotizar Seguro“ y completar
                      el formulario con los datos solicitados ¡Y listo!
                    </p>
                  </Collapsible>
                  <Collapsible title="¿Se encuentran en México? " type="list">
                    <p>
                      Nuestras oficinas se encuentran en Argentina, Chile y Colombia. Próximamente
                      desembarcaremos en México y en otros países de LaTam.
                    </p>
                  </Collapsible>
                  <GroupingLine />
                </Column>
              </FlexCenter>
            </Row>
          </Container>
        </FlexCenter>
      </ExpertSection>
      <Banner
        textTitle="¡Contrata en pocos minutos!"
        textSubtitle="Empieza a ahorrar tiempo y dinero con la asesoría de 123Seguro."
      />
      <FooterLanding email="holamexico@123seguro.com" />
    </Layout>
  );
};

export default OrganicLandingMx;
